import _key from "./key";
import _is from "./is";
var exports = {};
var key = _key,
    is = _is;

exports = function by(k, v) {
  var exists = arguments.length == 1;
  return function (o) {
    var d = is.fn(k) ? k(o) : key(k)(o);
    return d && v && d.toLowerCase && v.toLowerCase ? d.toLowerCase() === v.toLowerCase() : exists ? Boolean(d) : is.fn(v) ? v(d) : d == v;
  };
};

export default exports;